import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import {
  ScaleIcon,
  LightBulbIcon,
  AcademicCapIcon,
  ShieldCheckIcon,
} from "@heroicons/react/24/outline";

import {
  TruckIcon,
  UserIcon,
  HandThumbUpIcon,
} from "@heroicons/react/24/outline";

const features_old = [
  {
    name: "Compétences",
    icon: AcademicCapIcon,
  },
  {
    name: "Indépendance",
    icon: ScaleIcon,
  },
  {
    name: "Réactivité",
    icon: LightBulbIcon,
  },
  {
    name: "Responsabilité",
    icon: ShieldCheckIcon,
  },
];

const features = [
  {
    name: "Des conseils d'expert fissures",
    description: "Fissurometre.fr",
    icon: UserIcon,
  },
  {
    name: "Les meilleurs produits",
    description: "Satisfaction assurée",
    icon: HandThumbUpIcon,
  },
  {
    name: "Livraison rapide chez vous",
    description: "24/48h",
    icon: TruckIcon,
  },
];

export default function Sidebar() {
  return (
    <div className="bg-white ">
      <div className="lg:text-center">
        <h3 className="mt-2 text-1xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
          Le meilleur des grandes marques
        </h3>
      </div>

      <div className="bg-white">
        <div className=" gap-y-4  sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
          <div className="mt-4 text-lg leading-6 text-green-200">
            <a href="https://buy.stripe.com/4gw4jy3TQ9s49oc00m" target="_blank">
              <StaticImage
                className="bg-white rounded-md"
                src="../../images/1_unite.jpg"
                alt="Fissuration"
              />
            </a>
          </div>
          <div className="group relative flex flex-col overflow-hidden border border-gray-200 bg-white">
            <a href="https://buy.stripe.com/4gw4jy3TQ9s49oc00m" target="_blank">
              <div className="flex flex-1 flex-col space-y-2 p-4">
                <h3 className="text-sm font-medium text-gray-900">
                  <span aria-hidden="true" className="absolute inset-0" />
                  Jauge Saugnac G1
                </h3>
                <p className="text-sm text-gray-500">
                  Mesure simple et précise des fissures et de leur évolution
                  dans le temps
                </p>
                <div className="flex flex-1 flex-col justify-end">
                  {/* <p className="text-sm italic text-gray-500">{product.options}</p> */}
                  <p className="text-base font-medium text-gray-900">
                    29,90€ TTC
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div className="py-4 bg-white">
        <div className="max-w-7xl">
          <div className="lg:text-center">
            <h3 className="mt-2 text-1xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
              Pourquoi choisir un produit sur Fissurometre.fr ?
            </h3>
          </div>

          <div className="mt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:gap-x-8 md:gap-y-10">
              {features.map((feature) => (
                <div key={feature.name} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-sky-500 text-white">
                      <feature.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900  py-2">
                      {feature.name}
                    </p>
                  </dt>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}
